import {
  axiosInstance as axios,
  paramsSerializerForDotnet,
} from "@/core/services/api.client";
import { AccountStatusTypes } from "@/core/types/AccountInfos";
import { AccountGroupTypes } from "@/core/types/AccountGroupTypes";

const prefix = "api/v1/tenant/";

const prefixv2 = "api/v2/tenant/";

export type AccountWizardType = {
  kycFormCompleted: boolean;
  paymentAccessGranted: boolean;
  welcomeEmailSent: boolean;
  noticeEmailSent: boolean;
};

const AccountService = {
  queryTradeStatByAccountNumber: async (
    accountNumber: number,
    criteria?: any
  ) =>
    (
      await axios.get(prefixv2 + "account/" + accountNumber + "/trade/stat", {
        params: criteria,
      })
    ).data,

  queryTradeStatByAccountNumbers: async (criteria?: any) =>
    (
      await axios.get(prefixv2 + "account/trade/stat", {
        params: criteria,
      })
    ).data,

  setDefaultClient: async (accountId: number, code: string) =>
    (
      await axios.put(
        prefix +
          "referral/code/" +
          code +
          "/account/" +
          accountId +
          "/default-client"
      )
    ).data,

  exportAccounts: async (formData: any) =>
    (await axios.post(prefix + "report/request", formData)).data,

  confirmAutoCreateAccount: async (id: number) =>
    (await axios.post(prefixv2 + "account/" + id + "/confirm-auto-create"))
      .data,

  deleteDemoAccount: async (id: number) =>
    (await axios.delete(prefix + "trade-demo-account/" + id)).data,
  queryDemoAccounts: async (criteria?: any) =>
    (await axios.get(prefix + "trade-demo-account", { params: criteria })).data,
  updateFundType: async (id: number, fundType: number) =>
    (
      await axios.put(
        prefix + "account/" + id + "/fund-type?fundType=" + fundType
      )
    ).data,

  getReferralCodeDetailByCode: async (code: string) =>
    (await axios.get(prefix + "referral/referred-by-account/" + code)).data,

  getReferralHistory: async (criteria?: any) =>
    (await axios.get(prefix + "referral/history", { params: criteria })).data,

  getReferralCode: async (criteria?: any) =>
    (await axios.get(prefix + "referral", { params: criteria })).data,

  queryAccountLog: async (criteria?: any) =>
    (
      await axios.get(prefix + "account/log", {
        params: criteria,
      })
    ).data,

  updateGroupName: async (id: number, formData: any) =>
    (
      await axios.post(
        prefix + "account/" + id + "/change/agent-group-name",
        formData
      )
    ).data,

  postRebateAgentRule: async (data: any) =>
    (await axios.post(prefix + "rebate-agent-rule", data)).data,

  clearLevelSetting: async (id: number) =>
    (
      await axios.put(
        prefix + "rebate-agent-rule/" + id + "/clear-level-setting"
      )
    ).data,

  updateLevelSettingItems: async (id: number, data: any) =>
    (
      await axios.put(
        prefix + "rebate-agent-rule/" + id + "/level-setting",
        data
      )
    ).data,

  getChildAccountStat: async (criteria?: any) =>
    (await axios.get(prefix + "account/child/stat", { params: criteria })).data,

  getChildRebateStat: async (criteria?: any) =>
    (
      await axios.get(prefix + "account/child/stat/rebate/symbol-grouped", {
        params: criteria,
      })
    ).data,

  resetWholesaleApplication: async (partyId: number) =>
    (
      await axios.delete(
        prefix +
          "user/" +
          partyId +
          "/permission/application-wholesale-disabled"
      )
    ).data,

  queryAccounts: async (criteria?: any) =>
    (
      await axios.get(prefix + "account", {
        params: criteria,
        paramsSerializer: paramsSerializerForDotnet,
      })
    ).data,

  fuzzySearchAccounts: async (
    fuzzyKey: string,
    currentPage = 1,
    pageSize = 10
  ) =>
    (
      await axios.get(prefix + "search/account", {
        params: { q: fuzzyKey, page: currentPage, pageSize },
      })
    ).data,

  fuzzySearchTradeAccounts: async (
    fuzzyKey: string,
    currentPage = 1,
    pageSize = 10
  ) =>
    (
      await axios.get(prefix + "search/trade-account", {
        params: { q: fuzzyKey, page: currentPage, pageSize },
      })
    ).data,

  fuzzySearchAccountByCriteria: async (criteria: any) =>
    (
      await axios.get(prefix + "search/account", {
        params: criteria,
      })
    ).data,

  getServices: async () => (await axios.get(prefix + "trade/service")).data,

  getTradeAccount: async (criteria?: any) =>
    (await axios.get(prefix + "trade-account", { params: criteria })).data,

  getAccountDetailById: async (id: number) =>
    (await axios.get(prefix + "account/" + id)).data,

  refreshMTDataById: async (id: number) =>
    (await axios.get(prefix + "account/" + id + "/refresh")).data,

  queryTrades: async (criteria?: any) =>
    (await axios.get(prefix + "trade", { params: criteria })).data,

  queryTransactions: async (criteria?: any) =>
    (await axios.get(prefix + "transaction", { params: criteria })).data,

  queryApplications: async (criteria?: any) =>
    (
      await axios.get(prefix + "application", {
        params: criteria,
        paramsSerializer: paramsSerializerForDotnet,
      })
    ).data,

  approveApplicationsById: async (id: number, formData?: any) =>
    (await axios.put(prefix + "application/" + id + "/approve", formData)).data,

  rejectApplicationsById: async (id: number, formData?: any) =>
    (await axios.put(prefix + "application/" + id + "/reject", formData)).data,

  reverseApplicationsById: async (id: number, formData?: any) =>
    (
      await axios.put(
        prefix + "application/" + id + "/reverse-reject",
        formData
      )
    ).data,
  // tbd

  queryVerificationByCriteria: async (criteria?: any) =>
    (await axios.get(prefix + "verification/", { params: criteria })).data,

  getVerificationById: async (id: number) =>
    (await axios.get(prefix + "verification/" + id)).data,

  getTradeAccountReadOnlyCode: async (id: number) =>
    (await axios.get(prefix + "trade-account/" + id + "/read-only-code")).data,

  postSourceToTargetRebateRule: async (formData: any) =>
    (await axios.post(prefix + "rebate-rule", formData)).data,

  updateSourceToTargetRebateRule: async (id: number, formData: any) =>
    (await axios.put(prefix + "rebate-rule/" + id + "/items", formData)).data,

  getReadOnlyCodeMailReview: async (id: number, formData?: any) =>
    (
      await axios.post(
        prefix + "trade-account/" + id + "/read-only-code/notice/preview",
        {},
        { params: formData }
      )
    ).data,

  postReadOnlyCodeMail: async (id: number, formData?: any) =>
    (
      await axios.post(
        prefix + "trade-account/" + id + "/read-only-code/notice",
        {},
        { params: formData }
      )
    ).data,

  queryCommunications: async (criteria?: any) =>
    (await axios.get(prefix + "communicate", { params: criteria })).data,

  getAccountWizardStatus: async (id: number): Promise<AccountWizardType> =>
    (await axios.get(prefix + "account/" + id + "/wizard")).data,

  updateAccountStatus: async (
    accountId: number,
    status: AccountStatusTypes,
    formData: any
  ) =>
    (
      await axios.put(
        prefix + "account/" + accountId + "/status/" + status,
        formData
      )
    ).data,

  updateTradeAccountLeverage: async (accountId: number, leverage: any) =>
    (
      await axios.put(
        prefix + "trade-account/" + accountId + "/leverage/" + leverage
      )
    ).data,

  getUserKycHistory: async (partyId: number) =>
    (await axios.get(prefix + "kyc/" + partyId + "/history")).data,

  getUserLatestKyc: async (partyId: number) => {
    const res = (await axios.get(prefix + "kyc/" + partyId)).data;
    return res.verificationItems.length ? res.verificationItems[0].data : {};
  },

  createKycForm: async (partyId: number, formData: any) =>
    (await axios.post(prefix + "kyc/" + partyId, formData)).data,

  updateKycFormToAwaitingReview: async (partyId: number) =>
    (await axios.put(prefix + "kyc/" + partyId + "/awaiting-review")).data,

  createDeposit: async (formData: any) =>
    (await axios.post(prefix + "deposit", formData)).data,

  createWithdrawalForAccount: async (accountUid: number, formData: any) =>
    (
      await axios.post(
        prefix + "withdrawal/from-account/" + accountUid,
        formData
      )
    ).data,

  getFullAccountGroupNames: async (type: AccountGroupTypes, keywords = "") =>
    (
      await axios.get(prefix + "account/group/name-list", {
        params: { type, keywords },
      })
    ).data,

  assignAccountToSales: async (accountId: number, salesId: number) =>
    (
      await axios.post(
        prefix + "account/" + accountId + "/assign/sales/" + salesId
      )
    ).data,

  assignAccountToAgent: async (accountId: number, agentId: number) =>
    (
      await axios.post(
        prefix + "account/" + accountId + "/assign/agent/" + agentId
      )
    ).data,

  removeAccountFromAgent: async (accountId: number, agentId: number) =>
    (
      await axios.post(
        prefix + "account/" + accountId + "/remove/agent/" + agentId
      )
    ).data,

  renameSelfGroupName: async (groupId: number, formData) =>
    (await axios.put(prefix + "group/" + groupId, formData)).data,

  getActivityReport: async (id: number, formData) =>
    (await axios.post(prefix + "account/" + id + "/activity-report", formData))
      .data,

  getAccountById: async (id: number) =>
    (await axios.get(prefix + "account/" + id)).data,

  updateAccountTagsById: async (id: number, formData: any) =>
    (await axios.put(prefix + "account/" + id + "/tags", formData)).data,

  changeAgentGroup: async (id: number, agentId: number) =>
    (await axios.post(prefix + "account/" + id + "/change/agent/" + agentId))
      .data,

  removeAgentGroup: async (id: number, agentId: number) =>
    (await axios.post(prefix + "account/" + id + "/remove/agent/" + agentId))
      .data,

  changeSalesGroup: async (id: number, salesId: number) =>
    (await axios.post(prefix + "account/" + id + "/change/sales/" + salesId))
      .data,
  updateAccountLevelRule: async (id: number, hasLevelRule: number) =>
    (
      await axios.put(
        prefix +
          "account/" +
          id +
          "/has-level-rule?hasLevelRule=" +
          hasLevelRule
      )
    ).data,

  getCreditList: async (criteria?: any) =>
    (
      await axios.get(prefix + "audit/account-change-balance", {
        params: criteria,
      })
    ).data,

  getReferCode: async (id: number) =>
    (await axios.get(prefix + "account/" + id + "/referral-codes")).data,

  queryParentAccounts: async (id: number) =>
    (await axios.get(prefix + "account/" + id + "/parent-accounts")).data,
};

export const generateAutoCompleteHandler = (
  fetchListHandler: (
    keywords: string
  ) => Promise<Array<{ value: any; label: string }>>
): ((queryString: string, cb: (arg: any) => void) => Promise<void>) => {
  let prevStr = "";
  let prevList = Array<{ value: any; label: string }>();

  return async (queryString: string, cb: (arg: any) => void) => {
    if (
      queryString === "null" ||
      queryString === "" ||
      prevStr === queryString
    ) {
      cb(prevList);
      return;
    }
    prevStr = queryString == "null" ? "" : queryString;
    prevList = await fetchListHandler(prevStr);
    cb(prevList);
  };
};

export default AccountService;
